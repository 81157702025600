<template>
  <b-card title="List PCode">
    <b-col cols="12">
      <b-row class="mb-2">
        <b-col cols="4">
          <b-button
            variant="success"
            :to="{name:'pcode-add'}"
            v-if="permission.add"
          >
            Add PCode
          </b-button>
        </b-col>
        <b-col cols="4"/>
        <b-col cols="4">
          <b-form-input
            v-model="search"
            type="search"
            placeholder="Type to Search"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <b-table
            :items="pcodes"
            :fields="fields"
            :filter="search"
            :current-page="currentPage"
            :per-page="perPage"
            responsive
            show-empty
          >
            <template #cell(actions)="row">
              <b-dropdown  id="dropdown-dropleft" variant="primary" size="sm" text="Menu" no-caret>
                <template #button-content>
                  <feather-icon size="1x" icon="MenuIcon" />
                </template>
                <b-dropdown-item style="text-align:center" v-if="permission.edit">
                  <b-button  variant="outline-primary" @click="editPCode(row)" class="mr-1">
                    <feather-icon icon="EditIcon"/>
                  </b-button>
                </b-dropdown-item>
                <b-dropdown-item style="text-align:center" v-if="permission.delete">
                  <b-button variant="danger" @click="doDeletePCode(row)" class="mr-1">
                    <feather-icon icon="Trash2Icon"/>
                  </b-button>
                </b-dropdown-item>
              </b-dropdown>
            </template>

            <template #cell(validityStart)="row">
              {{ dates(row.item.validityStart) }}
            </template>

            <template #cell(validityEnd)="row">
              <!-- {{ dates(row.item.validityEnd) | formatDate }} -->
              {{ dates(row.item.validityEnd) }}
            </template>
          </b-table>
        </b-col>

        <b-col cols="12">
          <b-pagination
            :total-rows="dataSearch"
            :per-page="perPage"
            v-model="currentPage"
            style="float: right"
          />
        </b-col>
      </b-row>

    </b-col>
  </b-card>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import {userAccess, dateFormat} from '@/utils/utils'


export default {
  data() {
    return {
      search: '',
      fields: [
        { key: 'name', label: 'Name', filterable: true, sortable: true },
        { key: 'validityStart', label: 'Validity Start', filterable: true, sortable: true },
        { key: 'validityEnd', label: 'Validity End', filterable: true, sortable: true },
        // { key: 'distChan.name', label: 'Channel', filterable: true, sortable: true },
        { key: 'extension', label: 'Extension (Month)', filterable: true, sortable: true },
        { key: 'actions', label: 'Action' }
      ],
      currentPage: 1,
      perPage: 10
    }
  },
  mounted() {
    this.getAllPCodes();
  },
  methods: {
    ...mapActions({
      'getAllPCodes': 'pcode/getAllPCodes',
      'deletePCode': 'pcode/deletePCode',
    }),
    dates(date){
      return dateFormat(date)
    },
    doDeletePCode(row) {
      this.deletePCode(row.item._id).then(() => {
        this.getAllPCodes();
      }).catch((e) => {
        console.log(e);
      });
    },
    editPCode(row) {
      this.$router.push(`/promo/pcode/edit/${row.item._id}`)
    }
  },
  computed: {
    ...mapState({
      'pcodes': (state) => state.pcode.pcodes,
    }),
    dataSearch(){
        let paginationLength;
        paginationLength = this.pcodes.filter(el => {
            return el.name.includes(this.search);
        })
        if(this.search != ''){
            return paginationLength.length
        }else{
            return this.pcodes.length
        }
    },
    permission() {
      return userAccess("Pcode", "warranty_master_menu");
    },
  },
  created() {
    document.title = 'PCode | RSP'
  },
}
</script>
